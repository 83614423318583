import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login'

Vue.use(Router)

const isNotAuthenticated = (to, from, next) => {
  if (localStorage.getItem("orgId") == null) {
    next()
    return
  }
  next('/')
}
const isAuthenticated = (to, from, next) => {
  if (localStorage.getItem("orgId") != null) {
    next()
    return
  }
  next('/login')
}

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: isNotAuthenticated
    },
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      beforeEnter: isAuthenticated
    },
    {
      path: '/new/loc_entity',
      name: 'create_loc_entity',
      component: () => import('./views/ZeroLocEntityView.vue'),
      props: { "addOrEdit": 'add' },
      beforeEnter: isAuthenticated
    },
    {
      path: '/edit/loc_entity',
      name: 'edit_loc_entity',
      component: () => import('./views/EditLocEntityView.vue'),
      props: { "addOrEdit": 'edit' },
      beforeEnter: isAuthenticated
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => import('./views/Customers.vue'),
      beforeEnter: isAuthenticated,
      children:[
        {
          path:'customer/:custId',
          name: 'viewcustomer',
          component: () => import('./views/Customers.vue')
        }
      ]
    },
    {
      path: '/analytics',
      name: 'analytics',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('./views/Analytics.vue')
    },
    {
      path: '/loss',
      name: 'loss',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('./views/Loss.vue')
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('./views/Invoices.vue'),
      beforeEnter: isAuthenticated,
      children:[
        {
          path:'invoice/:invoiceId',
          name: 'viewinvoice',
          component: () => import('./views/Invoices.vue')
        }
      ]
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('./views/Settings.vue')
    },
    {
      path: '/settings/manage-devices/add-meter',
      component: () => import('./components/settings/AddMeterView.vue'),
    },
    {
      path: '/devices',
      component: () => import('./views/Meters.vue')
    },
    {
      path: '/devicecontrol',
      component: () => import('./views/DeviceControl.vue')
    },
    {
      path: '/settings/manage-custom-fields',
      component: () => import('./components/settings/ManageCustomFields.vue')
    },
    {
      path: '/settings/manage-invoice-templates',
      component: () => import('./components/settings/ManageInvoiceTemplates.vue')
    },
    {
      path: '/settings/manage-tariffs',
      component: () => import('./components/settings/ManageTariffs.vue')
    },
    {
      path: '/settings/org-profile',
      component: () => import('./components/settings/OrgProfile.vue')
    },
    {
      path: '/invoices/new',
      name: 'newinvoice',
      component: () => import('./views/NewInvoice.vue')
    },
    {
      path: '/new',
      name: 'New',
      component: () => import('./views/New.vue'),
      children:[
        {
          path:'customer',
          name: 'newcustomer',
          component: () => import('./components/addOrEdit/Customer.vue')
        },
        {
          path:'customfield',
          name: 'newcustomfield',
          component: () => import('./components/addOrEdit/CustomField.vue')
        },
        {
          path:'tariff',
          name: 'newtariff',
          component: () => import('./components/addOrEdit/Tariff.vue')
        }
      ]
    },
    {
      path: '/edit',
      name: 'Edit',
      component: () => import('./views/New.vue'),
      children:[
        {
          path:'customer/:custId',
          name: 'editcustomer',
          component: () => import('./components/addOrEdit/Customer.vue')
        },
        {
          path:'customfield',
          name: 'editcustomfield',
          component: () => import('./components/addOrEdit/CustomField.vue'),
          props: true
        },
        {
          path:'tariff',
          name: 'edittariff',
          component: () => import('./components/addOrEdit/Tariff.vue'),
          props: true
        }
      ]
    }
  ]
})

// {
//   path: '/customers/new',
//   name: 'addCustomer',
//   component: () => import('./components/addOrEdit/Customer.vue'),
//   props: { "addOrEdit": 'add' },
//   beforeEnter: isAuthenticated
// },
// {
//   path: '/customers/edit',
//   name: 'editCustomer',
//   component: () => import('./components/addOrEdit/Customer.vue'),
//   props: { "addOrEdit": 'edit' },
//   beforeEnter: isAuthenticated
// }