import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import MDMStore from './modules/mdmStore'
import authStore from './modules/authStore'
Vue.use(Vuex);
export default new Vuex.Store({
  modules:{
    MDMStore:MDMStore,
    authStore:authStore
  },
  plugins: [
    createPersistedState({ paths: ['authStore'] })
  ],
});