import apiHelper from "../../apiHelper";
// import createPersistedState from "vuex-persistedstate";
// import Cookies from "js-cookie";
const state = {
    loadingStatus: {},
    login:{},
    loginSuccess: "",
    orgDetails: {},
    orgInvoiceTemplates:[],
    orgDefaultInvoiceTemplate:{},
    locationEntityTypes: [],
    locEntityTree:[],
    meters: [],
    meterKinds:[],
    orgCustomFields:[],
    orgTariffs:[],
    invoiceCustomFields:[],
    selectedInvoice:{},
    selectedInvoiceInfo:{},
    snackbarDetails:{},
    measurements: [],
    selectedMeterDataSources: [],
    selectedMeter: "",
    selectedDataSources: [],
    customers: [],
    tariffs: [],
    invoices: []
};
const initialState = Object.assign({}, state);
const mutations = {
    RESET_STATE(state){
        for (let prop in state) {
            state[prop] = initialState[prop]
        }
    },
    SET_LOADING_STATUS(state, status) {
        state.loadingStatus = { ...state.loadingStatus,
            [status.statusProperty]: {
                "pending": status.statusValue,
                "statusCode": status.statusCode
            }
        }
    },
    SET_LOGIN(state, user) {
        state.login = Object.assign({}, user);
    },
    SET_LOGIN_STATUS(state, status) {
        state.loginSuccess = status;
    },
    SET_MEASUREMENTS(state, measurements) {
        state.measurements = measurements;
    },
    SET_ORG_DETAILS(state, orgDetails) {
        state.orgDetails = Object.assign({}, orgDetails);
    },
    SET_ORG_INVOICE_TEMPLATES(state, templates){
        state.orgInvoiceTemplates = templates;
    },
    SET_ORG_DEFAULT_INVOICE_TEMPLATE(state, template) {
        state.orgDefaultInvoiceTemplate = Object.assign({}, template);
    },
    SET_LOCATION_ENTITY_TYPES(state, locationEntityTypes) {
        state.locationEntityTypes = locationEntityTypes;
    },
    SET_LOC_ENTITY_TREE(state, tree) {
        state.locEntityTree = tree;
    },
    SET_METER_KINDS(state, data) {
        state.meterKinds = [];
        state.meterKinds = data;
    },
    SET_METERS(state, data) {
        // data.meters.forEach(meter => {
        //     if (meter.kind == 32839 || meter.kind == 32817) {
        //         Object.assign(meter, { gatewayId: data.gatewayId });
        //         state.meters.push(meter);
        //     }
        // })
        state.meters = [];
        state.meters = data;
    },
    SET_INVOICE_CUSTOM_FIELDS(state, fields) {
        state.invoiceCustomFields = fields;
    },
    SET_ORG_CUSTOM_FIELDS(state, fields) {
        state.orgCustomFields = fields;
    },
    SET_ORG_TARIFFS(state, tariffs) {
        state.orgTariffs = tariffs;
    },
    SET_SELECTED_INVOICE(state, invoice) {
        state.selectedInvoice = invoice;
    },
    SET_SELECTED_INVOICE_INFO(state, invoiceInfo){
        state.selectedInvoiceInfo = Object.assign({}, invoiceInfo);
    },
    SET_SNACKBAR_DETAILS(state, data) {
        state.snackbarDetails = Object.assign({}, data);
    },
    SET_CUSTOMERS(state, customers) {
        state.customers = [];
        state.customers = customers;
    },
    FILTER_SELECTED_METER_DATA_SOURCES(state, dataSources) {
        state.selectedMeterDataSources = [];
        state.selectedMeterDataSources = dataSources;
    },
    SET_SELECTED_METER_DATA_SOURCES(state, dataSources) {
        // state.selectedDataSources=[];
        state.selectedDataSources = [];
        state.selectedDataSources = dataSources;
    },
    SET_SELECTED_METER(state, meter) {
        // state.selectedDataSources=[];
        state.selectedMeter = meter;
    },
    SET_TARIFFS(state, tariffs) {
        state.tariffs=[];
        state.tariffs = tariffs;
    },
    SET_INVOICES(state, invoices) {
        state.invoices = invoices;
    }
};
const actions = {
    async login({ commit }, data) {
        var loginDetails = await apiHelper("POST", "/mdm/api/login", {
            "username": data.userCredentials.username,
            "password": data.userCredentials.password
        });
        if (typeof (loginDetails.data) == "object") {
            localStorage.setItem("token", "success");
            localStorage.setItem("orgId", loginDetails.data.organization_id);
            data.router.push({
                path: '/'
            });
        }
        commit("SET_LOGIN",loginDetails.data)
    },
    async getOrgDetails({ commit }, data) {
        commit("SET_LOADING_STATUS", {
            statusProperty: "LOC_ENTITY_TREE",
            statusValue: true,
            statusCode: null
        });
        var orgDetails = await apiHelper("GET","mdm/api/getOrgDetails/" + localStorage.getItem('orgId'));
        
        // apiHelper("GET","mdm/api/getOrgDetails/" + localStorage.getItem('orgId'));
        if (orgDetails.data.hasOwnProperty("orgDetails")) {
            commit("SET_ORG_DETAILS", orgDetails.data.orgDetails);
            if( orgDetails.data.orgDetails.hasOwnProperty('org_templates') && orgDetails.data.orgDetails.org_templates.length > 0){
               var defaultTemplate = orgDetails.data.orgDetails.org_templates.find((template) => template.org_invoice_templates.isDefault == true);
               commit("SET_ORG_DEFAULT_INVOICE_TEMPLATE", defaultTemplate);
               commit("SET_ORG_INVOICE_TEMPLATES", orgDetails.data.orgDetails.org_templates);
               commit("SET_ORG_INVOICE_TEMPLATES", orgDetails.data.orgDetails.org_templates);
            }
            commit("SET_ORG_DETAILS", orgDetails.data.orgDetails);
        }
        commit("SET_LOADING_STATUS", {
            statusProperty: "LOC_ENTITY_TREE",
            statusValue: false,
            statusCode: orgDetails.status
        });
        var gateways = orgDetails.data.orgDetails.gateways;
        commit("SET_LOADING_STATUS", {
            statusProperty: "DEVICE_STATUS",
            statusValue: true,
            statusCode: null
        });
        // commit("SET_SNACKBAR_DETAILS", {text:"Meters loaded", status:"success"});
        
    for (const gateway of gateways) {
         await this.dispatch("MDMStore/getGatewayInfo",{gateway_serial_number:gateway.serial_number})
        .then( async result => {
          var filteredMetersbyKind = result.filter(
            obj => ((obj.kind == 32817) || (obj.kind == 36869) || (obj.kind == 32839))
          );
          var meters = filteredMetersbyKind.map(function(obj) {
            var rObj = {};
            rObj["name"] = obj.meta.name || obj.id;
            rObj["serial_number"] = obj.id;
            rObj["meter_kind_idx"] = obj.kind;
            rObj["gateway_idx"] = gateway.id ;
            rObj["isOffline"] = obj.hasOwnProperty('isOffline') ? obj.isOffline.toString() : "false"; 
            rObj["org_idx"] = localStorage.getItem("orgId");
            return rObj;
          });
          await this.dispatch("MDMStore/updateMeterStatus",{meters:meters, isGatewayOffline: "false", gateway: gateway.id })
        }).catch(err => {
           this.dispatch("MDMStore/updateMeterStatus",{meters:[],isGatewayOffline: "true", gateway: gateway.id})
          })
    }
     await this.dispatch("MDMStore/getMeters");
     commit("SET_LOADING_STATUS", {
        statusProperty: "DEVICE_STATUS",
        statusValue: false,
        statusCode: 200
    });
        // if (orgDetails.data.org_loc_entities.length == 0) {
        //     data.router.push({
        //         path: '/new/loc_entity'
        //     });
        //     return;
        // }
    },
    async saveLocEntityTree({ commit }, data) {
        var postData = data.data;
        var result = await apiHelper("POST","mdm/api/createLocEntityTree", { "data": postData });
        return result;
    },
    async deleteCustomer({ commit }, data) {
        var result = await apiHelper("DELETE", "mdm/api/customer/" + data.custId);
        return result;
    },
    async deleteTariff({ commit }, data) {
        var result = await apiHelper("DELETE", "mdm/api/tariff/" + data.tariffId);
        return result;
    },
    async deleteCustomField({ commit }, data) {
        var result = await apiHelper("DELETE", "mdm/api/customfield/" + data.custFieldId);
        return result;
    },
    async upsertOrgCustomField({ commit }, data) {
        var postData = data.data;
        var result = await apiHelper("POST","mdm/api/upsertOrgCustomField", { "custom_field": postData });
        return result;
    },
    async upsertOrgTariff({ commit }, data) {
        var postData = data.data;
        var result = await apiHelper("POST","mdm/api/upsertOrgTariff", { "tariff": postData });
        return result;
    },
    async updateLocEntityTree({ commit }, data) {
        var postData = data.data;
        var result = await apiHelper("POST","mdm/api/updateLocEntityTree", { "data": postData });
        return result;
    },
    async getLocEntityTree({ commit }) {
        var result = await apiHelper("GET", "mdm/api/getLocEntities/" + localStorage.getItem('orgId'));
        commit("SET_LOC_ENTITY_TREE", result.data);
        return result.data;
        // if (orgDetails.data.org_loc_entities.length == 0) {
        //     data.router.push({
        //         path: '/new/loc_entity'
        //     });
        //     return;
        // }
    },
    async getMeters({ commit }, data) {
        // commit("SET_LOADING_STATUS", {
        //     statusProperty: "DEVICE_STATUS",
        //     statusValue: true,
        //     statusCode: null
        // });
        var result =  await apiHelper("GET","mdm/api/getMetersbyOrg/" + localStorage.getItem('orgId'));
        commit("SET_METERS", result.data);
        
        // commit("SET_SNACKBAR_DETAILS", {text:"Meters loaded", status:"success"});
        // commit("SET_LOADING_STATUS", {
        //     statusProperty: "DEVICE_STATUS",
        //     statusValue: false,
        //     statusCode: result.status
        // });
        return result.data;
    },
    async getMeterKinds({ commit }) {
        var result = await apiHelper("GET","mdm/api/getMeterKinds");
        commit("SET_METER_KINDS", result.data);
    },
    async saveLocEntityMeter({ commit }, data) {
        var postData = data.data;
        var result = await apiHelper("POST","mdm/api/addLocEntityMeters", { "data": postData });
        return result;
    },
    async addCustomer({ commit }, data) {
        var result = await apiHelper("POST","mdm/api/addCustomer", { "customer": data.customer, "meters":data.meters });
        return result.data;
    },
    async updateCustomer({ commit }, data) {
        var result = await apiHelper("POST","mdm/api/updateCustomer", { "customer": data.customer, "meters":data.meters });
        return result.data;
    },
    async getLocationEntityTypes({ commit }) {
        var locationEntityTypes = await apiHelper("GET","mdm/api/getLocEntityTypes");
        commit("SET_LOCATION_ENTITY_TYPES", locationEntityTypes.data);
    },
    async getGatewayInfo({ commit },data){
        var gatewayInfo =  await apiHelper("GET","mdm/gateway/" + data.gateway_serial_number);
        return gatewayInfo.data.data;
    },
    async addMeters({ commit },data) {
        var postData = data.meters;
        var result = await apiHelper("POST","mdm/api/addMeters", { "data": postData });
        return result;
    },
    async updateMeterStatus({ commit },data) {
        var meters = data.meters;
        var isGatewayOffline = data.isGatewayOffline;
        var gateway = data.gateway;
        var result = await apiHelper("POST","mdm/api/updateMeterStatus", { "meters": meters , isGatewayOffline: data.isGatewayOffline, gateway:data.gateway  });
        return result;
    },
    async getCustomerInvoiceInfo({ commit },data) {
        var result = await apiHelper("GET","mdm/api/getCustomerInvoiceInfo/"+ data.custId);
        return result.data;
    },
    async getOrgCustomFields({ commit }) {
        var customFields = await apiHelper("GET","mdm/api/getOrgCustomFields/" + localStorage.getItem('orgId'));
        commit("SET_ORG_CUSTOM_FIELDS", customFields.data)
    },
    async getOrgTariffs({ commit }) {
        var tariffs = await apiHelper("GET","mdm/api/getOrgTariffs/" + localStorage.getItem('orgId'));
        commit("SET_ORG_TARIFFS", tariffs.data)
    },
    async getCustomers({ commit }) {
        var customers = await apiHelper("GET","mdm/api/getCustomers/" + localStorage.getItem('orgId'));
        commit("SET_CUSTOMERS", customers.data)
    },
    async getCustomerInfo({ commit }, data) {
        var customerInfo = await apiHelper("GET","mdm/api/getCustomerInfo/" + data.custId);
        return customerInfo.data;
    },
    async getDataSources({ state, commit }, data) {
        // 
        try {
            commit("SET_LOADING_STATUS", {
                statusProperty: "METER_DATA_PARAMS",
                statusValue: true,
                statusCode: null
            });
            var selectedMeterDataSources =  await apiHelper("GET","mdm/gateway/" + data.deviceInfo.gatewayId + "/" + data.deviceInfo.meterId + "/capability?ds=");
            var availableDataSources = selectedMeterDataSources.data.filter(ds => state.measurements.includes(ds.name));
            commit("FILTER_SELECTED_METER_DATA_SOURCES", availableDataSources);
            commit("SET_LOADING_STATUS", {
                statusProperty: "METER_DATA_PARAMS",
                statusValue: false,
                statusCode: 200
            });
          } catch (error) {
              console.log(error);
              commit("SET_LOADING_STATUS", {
                statusProperty: "METER_DATA_PARAMS",
                statusValue: false,
                statusCode: 200
            });
              commit("FILTER_SELECTED_METER_DATA_SOURCES",  state.measurements);
          }
    },
    async getInfluxMeasurements({ commit }) {
        var measurements = await apiHelper("GET","mdm/influx/measurements");
        commit("SET_MEASUREMENTS", measurements.data);
    },
    async getTariffs({ commit }, data) {
        var tariffs = await apiHelper("GET","mdm/api/getTariffs/" +localStorage.getItem('orgId'));
        commit("SET_TARIFFS", tariffs.data);
    },
    async getCustomFields({ commit }, data) {
        var fields = await apiHelper("GET","mdm/api/getCustomFields/" + localStorage.getItem('orgId'));
        commit("SET_INVOICE_CUSTOM_FIELDS", fields.data);
    },
    async getInvoiceInfo({ commit }, data) {
        var invoiceInfo = await apiHelper("GET","mdm/api/getInvoiceInfo/" + data.invoiceId);
        commit("SET_SELECTED_INVOICE_INFO", invoiceInfo.data);
        return invoiceInfo.data;
    },
    async updateOrgProfile({ commit }, data) {
        var orgProfile = await apiHelper("POST","mdm/api/updateOrgProfile", { "data":  data.org });
        commit("SET_ORG_DETAILS", orgProfile.data.org);
    },
    async updateDefaultInvTemplate({ commit }, data) {
        var result = await apiHelper("POST","mdm/api/updateDefaultInvoiceTemplate", data.template );
        return result.data;
        // commit("SET_ORG_DETAILS", orgProfile.data.org);
    },
    async getInvoices({ commit }, data) {
        var invoices = await apiHelper("GET","mdm/api/getInvoices/" + localStorage.getItem('orgId'));
        commit("SET_INVOICES", invoices.data)
    },
    // async getInvoicesByCustomer({ commit }, data) {
    //     var invoices = await apiHelper("GET","mdm/api/getInvoicesByCustomer/" + data.custId);
    //     commit("SET_SELECTED_CUSTOMER_INVOICES", invoices.data)
    // },
    
};


const getters = {
    // getAuthenticationToken: () => {
    // if (state.orgDetails.hasOwnProperty('jwt')) {
    //     return state.orgDetails.jwt;
    // }
    // return localStorage.getItem('token');
    // },
    getterLogin : (state) => {
        return state.login;
    },
    getterSnackbarDetails: (state) =>{
        return state.snackbarDetails;
    },
    getterloadingState: (state) => (action) => {
        if (state.loadingStatus.hasOwnProperty(action)) {
            return state.loadingStatus[action]["pending"];
        }
    },
    getterOrgId: () => {
        if (state.orgDetails.hasOwnProperty('id')) {
            return state.orgDetails.id;
        }
        return ""
    },
    getterLocEntityTypes: () => {
        return state.locationEntityTypes;
    },
    getterOrgGateways: () => {
        if (state.orgDetails.hasOwnProperty('gateways')) {
            return state.orgDetails.gateways;
        }
        return []
    },
    getterOrgDetails: () => {
        return state.orgDetails;
    },
    getterOrgInvoiceTemplates: (state) =>{
        return state.orgInvoiceTemplates;
    },
    getterOrgDefaultInvoiceTemplate: () => {
        return state.orgDefaultInvoiceTemplate;
    },
    getterLocEntityTree:() =>{
        return state.locEntityTree;
    },
    getterOrgName:() =>{
        if(state.orgDetails.hasOwnProperty("name")){
            return state.orgDetails.name
        }
        return ""
    },
    getterlocEntityTreeLength:()=>{
        return state.locEntityTree.length;
    },
    getterOrgCustomFields:()=>{
        return state.orgCustomFields;
    },
    getterOrgTariffs :()=>{
        return state.orgTariffs;
    },
    getterMeters: (state) => {
        return state.meters;
    },
    getterGatewayMeters:(state) =>(gatewayId) =>{
        return state.meters.filter(meter => meter.gateway_idx == gatewayId);
    },
    getterMeterKinds: (state) => {
        return state.meterKinds.map(obj => obj.id);
    },
    getterMeterKindName:(state) => (meterKindId) => {
        var meterKind = state.meterKinds.find(c => c.id == meterKindId);
       return meterKind.hasOwnProperty('kind_name') ? meterKind.kind_name : "";
    },
    getterGatewayName:(state) => (gatewayId) => {
        if( state.orgDetails.hasOwnProperty('gateways')){
            var gateway = state.orgDetails.gateways.find(({id}) => id == gatewayId)
        }
       return gateway.hasOwnProperty('name') ? gateway.name : "";
    },
    getterGateways:(state)=>{
        if(state.orgDetails.hasOwnProperty("gateways")){
            return state.orgDetails.gateways;
        }
        return [];
    },
    getterGatewaySerialNumber:(state) => (gatewayIdx) => {
        if(state.orgDetails.hasOwnProperty("gateways")){
            var gateway =  state.orgDetails.gateways.find(gateway => gatewayIdx == gateway.id);
            return gateway.hasOwnProperty("serial_number") ? gateway.serial_number : "invalidGateway"
        }
        return "";
    },
    getterSelectedDataSources: (state) => {
        return state.selectedDataSources;
    },
    getterSelectedMeterDataSources: (state) => {
        return state.selectedMeterDataSources;
    },
    getterSelectedMeter: (state) => {
        return state.selectedMeter;
    },
    getterCustomers: (state) => {
        return state.customers;
    },
    getterSelectedInvoice: (state) => {
        return state.selectedInvoice;
    },
    getterOnlineMetersCount: (state) => {
        if(state.meters.length != 0){
            return state.meters.filter(meter => meter.isOffline == 'false').length;
        }
        return ""
    },
    getterOfflineMetersCount: (state) => {
        if(state.meters.length != 0){
            return state.meters.filter(meter => meter.isOffline == 'true').length;
        }
        return ""
    },
    getterFaultMetersCount: (state) => {
        if(state.meters.length != 0){
            return state.meters.filter(meter => meter.isOffline != 'true' && meter.isOffline != 'false').length;
        }
        return ""
    },
    getterTariffs: (state) => {
        return state.tariffs;
    },
    getterInvoices: (state) => {
        return state.invoices;
    },
    getterLocEntityTypeName:(state) =>(locEntityId) =>{
        var type =  state.locationEntityTypes.find(type => locEntityId == type.id);
        return (type != null && type != undefined) ?  type.loc_entity_type_name : "";
    },
    getterSelectedInvoiceInfo: (state) => {
        return state.selectedInvoiceInfo;
    },
    getterCustById:(state)=>(custId) =>{
        return state.customers.find(customer => customer.id == custId);
    },





    getOnlineMeters: (state) => {
        var onlineMeters = [];
        if (state.meters.length != 0) {
            state.meters.forEach(device => {
                if (device.hasOwnProperty("offline") && device.offline != true) {
                    onlineMeters.push(device);
                }
                if (device.hasOwnProperty("isOffline") && device.isOffline != true) {
                    onlineMeters.push(device);
                }
            });
        }
        return onlineMeters;
    },
    getOfflineMeters: (state) => {
        var offlineMeters = [];
        if (state.meters.length != 0) {
            state.meters.forEach(device => {
                if (device.hasOwnProperty("offline") && device.offline) {
                    offlineMeters.push(device);
                }
                if (device.hasOwnProperty("isOffline") && device.isOffline) {
                    offlineMeters.push(device);
                }
            });
        }
        return offlineMeters;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}