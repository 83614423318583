<template>
  <div>
    <v-alert v-model="invalidCredentialsAlert" type="error" dismissible>Invalid credentials</v-alert>
    <v-container>
      <v-layout>
        <v-spacer></v-spacer>
        <v-flex xs4>
          <v-card class="pa-5">
            <div style="padding:16px">
              <img src="@/assets/tekpea.png" />
            </div>
            <v-form @submit.prevent="login">
              <v-text-field
                :error-messages="nameErrors"
                @input="$v.username.$touch()"
                @blur="$v.username.$touch()"
                v-model="username"
                label="Name"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                :error-messages="passwordErrors"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                v-model="password"
                label="Password"
                type="password"
                required
              ></v-text-field>
              <v-btn type="submit">Login</v-btn>
            </v-form>
          </v-card>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },
  data: () => ({
    username: "",
    password: "",
    invalidCredentialsAlert: false
  }),
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("Name is required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required.");
      return errors;
    }
  },
  methods: {
    login() {
      var self = this;
      let userCredentials = Object.assign(
        {
          username: self.username,
          password: self.password
        },
        {}
      );
      self.$store.dispatch({
        type: "authStore/login",
        userCredentials: userCredentials,
        router: self.$router
      });
    }
  }
};
</script>