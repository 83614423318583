// import Vue from 'vue'
// import Vuetify from 'vuetify/lib'
// // import 'vuetify/src/stylus/app.styl'
// import 'vuetify/dist/vuetify.min.css'

// Vue.use(Vuetify, {
//   theme: {
//     primary: '#3D5AFE',
//     secondary: '#424242',
//     accent: '#82B1FF',
//     error: '#FF5252',
//     info: '#2196F3',
//     success: '#4CAF50',
//     warning: '#FFC107'
//   },
//   options: {
//     customProperties: true
//   },
//   iconfont: 'md', //using material design icon
// })
// import Vue from 'vue'
// import Vuetify from 'vuetify'

import light from './theme'
import "../stylus/main.styl"
// Vue.use(Vuetify)
// export default new Vuetify(
  import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({ theme: {
  themes: { light },
},
options: {
  customProperties: true
},
iconfont: 'md' })