<template>
  <v-app>
    <v-app-bar app fixed clippedLeft v-if="hideMenu">
      <v-app-bar-nav-icon @click.stop="drawer.mini = !drawer.mini"></v-app-bar-nav-icon>
      <v-toolbar-title>{{appTitle}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu bottom left v-if="orgId != null && orgId != ''">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" icon v-on="on">
            <v-icon>settings_applications</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="item in navBarAdminItems" :key="item.title" :to="item.path">
            <!-- <v-list-item-action>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-action> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
       <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 elevation-0"
                icon
                color="primary"
                v-on="on"
                @click="logout()"
              >
                <v-icon dark>exit_to_app</v-icon>
              </v-btn>
            </template>
            <span>Sign out</span>
          </v-tooltip>
    </v-app-bar>
    <v-content class="mdm-content">
      <v-navigation-drawer
        v-if="hideMenu"
        :clipped="true"
        :fixed="true"
        :permanent="true"
        :mini-variant="drawer.mini"
        v-model="drawer.open"
        dark
        app
      >
        <v-list dense>
          <v-list-item
            active-class="primary--text"
            v-for="(item, index) in filterNavbarItems"
            :key="index"
            :to="item.path"
          >
            <v-list-item-action>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-html="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <keep-alive>
        <router-view />
      </keep-alive>
      <v-snackbar v-if="Object.keys(snackbar).length != 0" :timeout="timeout"  :color="snackbar.status" v-model="showSnackbar">{{snackbar.text}}</v-snackbar>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showSnackbar: false,
      timeout:1000,
      snackbarDetails: {},
      drawer: {
        open: false,
        clipped: false,
        fixed: false,
        permanent: true,
        mini: true
      },
      navbarItems: [
        {
          path: "/",
          title: "Home",
          icon: "home"
        },
        {
          path: "/devices",
          title: "Meters",
          icon: "developer_board"
        },
        {
          path: "/devicecontrol",
          title: "Device Control",
          icon: "control_point"
        },
        {
          path: "/analytics",
          title: "Analytics",
          icon: "insert_chart"
        },
        {
          path: "/loss",
          title: "Loss",
          icon: "trending_down"
        },
        {
          path: "/customers",
          title: "Customers",
          icon: "people"
        },
        {
          path: "/invoices",
          title: "Invoices",
          icon: "assignment"
        },
        {
          path: "/settings",
          title: "Settings",
          icon: "settings_applications"
        }
      ],
      navBarAdminItems: [
        {
          path: "/settings/org-profile",
          title: "Organization Profile"
        }
        // {
        //   path: "/settings/device-control",
        //   title: "Device Control",
        //   icon: "square_foot"
        // }
      ]
    };
  },
  computed: {
    hideMenu() {
      return this.$route.name !== "Login";
    },
    filterNavbarItems(){
      var self = this;
      if(this.$store.getters["authStore/getterLogin"].hasOwnProperty('user_role')){
       return this.$store.getters["authStore/getterLogin"].user_role.modules.map(function(m){
          var obj = self.navbarItems.find(item => item.title === m.description);
          if(obj != undefined  && obj != null){
            return obj;
          }
        })
      }
    },
    orgId() {
      return this.$store.getters["MDMStore/getterOrgId"];
    },
    appTitle() {
      return this.$store.getters["authStore/getterAppTitle"];
    },
    snackbar: {
      get: function() {
        this.showSnackbar = true;
        return this.$store.getters["MDMStore/getterSnackbarDetails"];
      },
      set: function(newValue) {
        this.snackbarDetails = Object.assign({}, newValue);
      }
    }
  },
  mounted() {},
  created() {
    // this.$vuetify.theme.dark = true
  },
  methods:{
    logout(){
      localStorage.removeItem("orgId");
      this.$router.replace("/login");
      this.$store.commit("MDMStore/RESET_STATE");
    }
  }
};
</script>
<style>
html { overflow-y: auto !important; }
.mdm-content {
  /* background: #fafafa; */
  background:#607D8B;
}
/* .theme--light.v-stepper .v-stepper__header */
.default-card {
  min-height: 250px;
}
@import url("https://fonts.googleapis.com/css?family=Questrial");

.v-application {
  font-family: "Questrial";
}

/* .application {
  font-family:  'Lato' !important;
} */
</style>

