import apiHelper from "../../apiHelper";
const state = {
    login:{},
    loginSuccess: "",
    appTitle:""
};
const initialState = Object.assign({}, state);
const mutations = {
    RESET_STATE(state){
        for (let prop in state) {
            state[prop] = initialState[prop]
        }
    },
    SET_LOGIN(state, user) {
        state.login = Object.assign({}, user);
    },
    SET_LOGIN_STATUS(state, status) {
        state.loginSuccess = status;
    },
    SET_APP_TITLE(state, title) {
        state.appTitle = title;
    },
};
const actions = {
    async login({ commit }, data) {
        var loginDetails = await apiHelper("POST", "/mdm/api/login", {
            "username": data.userCredentials.username,
            "password": data.userCredentials.password
        });
        if (typeof (loginDetails.data) == "object") {
            localStorage.setItem("token", "success");
            localStorage.setItem("orgId", loginDetails.data.organization_id);
            data.router.push({
                path: (loginDetails.data.user_role.active_module.path)
            });
        }
        commit("SET_LOGIN",loginDetails.data);
        if(loginDetails.data.user_role.description == "MDM Admin"){
            commit("SET_APP_TITLE","Meter Data Management");
        }else if(loginDetails.data.user_role.description == "Billing Admin"){
            commit("SET_APP_TITLE","Meter Billing System");
        }
    }
    
};


const getters = {
    getterLogin : (state) => {
        return state.login;
    },
    getterAppTitle : (state) => {
        return state.appTitle;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  
}